import { ImportStatus } from "@syla/shared/types/models/DataSourceBase";
import { DataSource } from "../../types/dataSource/dataSource";
import { DataSourceInputType } from "./DataSourceInputType";

// little helper to return the appropriate data source instruction
export const getDataSourceInstruction = ({
  dataSource,
  inputType,
  importStatus = ImportStatus.Off,
}: {
  dataSource: DataSource;
  inputType: DataSourceInputType;
  importStatus: ImportStatus | undefined;
}): string | undefined => {
  switch (inputType) {
    case DataSourceInputType.Api:
      return replaceTokens({
        text: dataSource.syncImportInstructions,
        dataSource,
      });
    case DataSourceInputType.Csv:
      return replaceTokens({
        text:
          dataSource.fileImportInstructions ||
          (importStatus == ImportStatus.Alpha ?
            defaultCsvAssistedImportInstructions
          : defaultCsvInstructions),
        dataSource,
      });
    case DataSourceInputType.Custom:
      return customImportInstruction;
  }
};

const defaultCsvAssistedImportInstructions = `## How to request an Assisted File Import:

1. Login to {name}.
1. Export your complete transaction history including your trades, deposits, withdrawals and any other transactions.
1. In Syla, click **Request Assisted Import** and follow the prompts to upload your transaction files.
1. Our team will keep you updated on the progress of the Assisted Import.
1. [Detailed Instructions](https://help.syla.com.au/en/articles/8328641-assisted-file-import) for the Assisted File Import are also available in our help center.`;

const defaultCsvInstructions = `## How to import your transactions:

1. Login to {name}.
1. Export your complete transaction history including your trades, deposits, withdrawals and any other transactions.
1. In Syla, upload your files and click **Secure Import**.`;

const customImportInstruction = `## How to manually import your transactions:
1. Download a copy of the template:
  [Ledgers CSV Template](/custom_ledgers_csv_template.csv)  
1. Follow the [Detailed Instructions](https://help.syla.com.au/en/articles/8281571-custom-csv-template-import-advanced) in our help center to enter your transactions into the template.
1. Once finished, upload your file and click Secure Import.
1. **Legacy Format**: If you're using the legacy format, download the [Transactions CSV Template](/custom_transactions_csv_template.csv).`;

const replaceTokens = ({
  text,
  dataSource,
}: {
  text: string | undefined;
  dataSource: DataSource;
}): string | undefined => text?.replaceAll("{name}", dataSource.name);
