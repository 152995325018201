import { DataSourceCode } from "@syla/shared/types/models/DataSourceBase";

export const buildExplorerUrl = ({
  code,
  txHash,
}: {
  txHash: string;
  code?: DataSourceCode;
}) => {
  const escapedHash = encodeURIComponent(txHash);
  switch (code) {
    case DataSourceCode.Algorand:
      return `https://allo.info/tx/${escapedHash}`;
    case DataSourceCode.Ethereum:
      return `https://etherscan.io/tx/${escapedHash}`;
    case DataSourceCode.BSC:
      return `https://bscscan.com/tx/${escapedHash}`;
    case DataSourceCode.Celo:
      return `https://celoscan.io/tx/${escapedHash}`;
    case DataSourceCode.Polygon:
      return `https://polygonscan.com/tx/${escapedHash}`;
    case DataSourceCode.FTM:
      return `https://ftmscan.com/tx/${escapedHash}`;
    case DataSourceCode.Cronos:
      return `https://cronoscan.com/tx/${escapedHash}`;
    case DataSourceCode.Moonbeam:
      return `https://moonscan.io/tx/${escapedHash}`;
    case DataSourceCode.Tezos:
      return `https://tzkt.io/${escapedHash}`;
    case DataSourceCode.Cardano:
      return `https://cardanoscan.io/transaction/${escapedHash}`;
    case DataSourceCode.Cosmos:
      return `https://www.mintscan.io/cosmos/tx/${escapedHash}`;
    case DataSourceCode.Flare:
      return `https://flare-explorer.flare.network/tx/${escapedHash}`;
    case DataSourceCode.Songbird:
      return `https://songbird-explorer.flare.network/tx/${escapedHash}`;
    case DataSourceCode.Avalanche:
      return `https://subnets.avax.network/c-chain/tx/${escapedHash}`;
    case DataSourceCode.Beam:
      return `https://subnets.avax.network/beam/tx/${escapedHash}`;
    case DataSourceCode.Stacks:
      return `https://explorer.hiro.so/txid/${escapedHash}?chain=mainnet`;
    case DataSourceCode.MultiversX:
      return `https://explorer.multiversx.com/transactions/${escapedHash}`;
    case DataSourceCode.Arbitrum:
      return `https://arbiscan.io/tx/${escapedHash}`;
    case DataSourceCode.Optimism:
      return `https://optimistic.etherscan.io/tx/${escapedHash}`;
    case DataSourceCode.Base:
      return `https://basescan.org/tx/${escapedHash}`;
    case DataSourceCode.Blast:
      return `https://blastscan.io/tx/${escapedHash}`;
    case DataSourceCode.Mode:
      return `https://explorer.mode.network/tx/${escapedHash}`;
    case DataSourceCode.Mantle:
      return `https://explorer.mantle.xyz/tx/${escapedHash}`;
    case DataSourceCode.ZkSyncEra:
      return `https://era.zksync.network/tx/${escapedHash}`;
    case DataSourceCode.Gnosis:
      return `https://gnosisscan.io/tx/${escapedHash}`;
    case DataSourceCode.HederaHashgraph:
      return `https://hashscan.io/mainnet/transaction/${escapedHash}`;
    case DataSourceCode.Scroll:
      return `https://scrollscan.com/tx/${escapedHash}`;
    case DataSourceCode.Metis:
      return `https://andromeda-explorer.metis.io/tx/${escapedHash}`;
    case DataSourceCode.Taiko:
      return `https://taikoscan.io/tx/${escapedHash}`;
    case DataSourceCode.Fraxtal:
      return `https://fraxscan.com/tx/${escapedHash}`;
    case DataSourceCode.Zora:
      return `https://explorer.zora.energy/tx/${escapedHash}`;
    case DataSourceCode.Cyber:
      return `https://cyberscan.co/tx/${escapedHash}`;
    case DataSourceCode.DFK:
      return `https://subnets.avax.network/defi-kingdoms/tx/${escapedHash}`;
    case DataSourceCode.Mint:
      return `https://explorer.mintchain.io/tx/${escapedHash}`;
    case DataSourceCode.Solana:
      return `https://solscan.io/tx/${escapedHash}`;
    case DataSourceCode.Chiliz:
      return `https://scan.chiliz.com/tx/${escapedHash}`;
    case DataSourceCode.XRP:
      return `https://xrpscan.com/tx/${escapedHash}`;
    case DataSourceCode.Stellar:
      return `https://stellar.expert/explorer/public/tx/${escapedHash}`;
    case DataSourceCode.Tron:
      return `https://tronscan.org/#/transaction/${escapedHash}`;
    case DataSourceCode.Thorchain:
      return `https://runescan.io/tx/${escapedHash}`;
    case DataSourceCode.Ton:
      return `https://tonscan.org/tx/${escapedHash}`;
    case DataSourceCode.Elastos:
      return `https://ela.elastos.io/tx/${escapedHash}`;
    case DataSourceCode.BnbBeaconChain:
      return `https://explorer.bnbchain.org/tx/${escapedHash}`;
    case DataSourceCode.Waves:
      return `https://wavesexplorer.com/transactions/${escapedHash}`;
    case DataSourceCode.ICON:
      return `https://tracker.icon.community/transaction/${escapedHash}`;
    case DataSourceCode.NearProtocol:
      return `https://nearblocks.io/txns/${escapedHash}`;
    case DataSourceCode.Theta:
      return `https://explorer.thetatoken.org/txs/${escapedHash}`;
    default:
      return `https://blockchair.com/search?q=${escapedHash}&from=syla`; // add `from=syla` for referral benefits
  }
};

export const showTransactionHashLink = (code?: DataSourceCode) => {
  switch (code) {
    case DataSourceCode.Qoin:
      return false;
    default:
      return true;
  }
};
