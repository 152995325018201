import { Decimal as DecimalCore } from "decimal.js";
import { formatStringWithCommas } from "../helpers/formatting";

const SIGNIFICANT_DIGITS = 32;

const config: DecimalCore.Config = {
  precision: SIGNIFICANT_DIGITS,
  rounding: 4,
  minE: -64,
  maxE: 64,
  defaults: true,
};
DecimalCore.set(config);

export const OVERFLOW_ERROR = `Decimal overflow: make sure Decimal range is inside 1e${config.maxE}`;

export class Decimal {
  static ZERO: Decimal;
  static ONE: Decimal;
  static TWO: Decimal;
  static TEN: Decimal;

  private _value: DecimalCore;

  private constructor(decimal: DecimalCore, context: string) {
    Decimal.validate(decimal, context);
    this._value = decimal;
  }

  private static validate = (decimal: DecimalCore, context: string) => {
    if (decimal.isNaN()) {
      throw new Error(`NaN result from ${context}`);
    }

    if (!decimal.isFinite()) {
      throw new Error(`${OVERFLOW_ERROR}. Context: ${context}`);
    }
  };

  public static from(value: string): Decimal {
    const rawDecimal = new DecimalCore(value).toSignificantDigits();
    const instance = new Decimal(
      rawDecimal,
      `Invalid initial Decimal with value ${value}`,
    );
    return instance;
  }

  static {
    Decimal.ZERO = Decimal.from("0");
    Decimal.ONE = Decimal.from("1");
    Decimal.TWO = Decimal.from("2");
    Decimal.TEN = Decimal.from("10");
  }

  static min(...n: Decimal[]) {
    return new Decimal(
      DecimalCore.min(...n.map((n) => Decimal._unwrap(n))),
      `min ${n.join(", ")}`,
    );
  }

  sign(): number {
    return this._value.s;
  }

  toString(): string {
    return this._value.toString();
  }

  toNumber(): number {
    return this._value.toNumber();
  }

  toJSON(): string {
    return this._value.toJSON();
  }

  isNaN(): boolean {
    return this._value.isNaN();
  }

  isFinite(): boolean {
    return this._value.isFinite();
  }

  abs(): Decimal {
    return new Decimal(this._value.abs(), `Decimal ${this._value} abs()`);
  }

  plus(value: string | Decimal): Decimal {
    const result = new Decimal(
      this._value.plus(Decimal._unwrap(value)),
      `${this._value} plus ${value}`,
    );
    return result;
  }

  minus(value: string | Decimal): Decimal {
    const result = new Decimal(
      this._value.minus(Decimal._unwrap(value)),
      `${this._value} minus ${value}`,
    );
    return result;
  }

  times(value: string | Decimal): Decimal {
    const result = new Decimal(
      this._value.times(Decimal._unwrap(value)),
      `${this._value} times ${value}`,
    );
    return result;
  }

  div(value: string | Decimal): Decimal {
    const result = new Decimal(
      this._value.div(Decimal._unwrap(value)),
      `${this._value} div ${value}`,
    );
    return result;
  }

  mod(value: string | Decimal): Decimal {
    const result = new Decimal(
      this._value.mod(Decimal._unwrap(value)),
      `${this._value} mod ${value}`,
    );
    return result;
  }

  negated(): Decimal {
    const result = new Decimal(this._value.neg(), `${this._value} negated`);
    return result;
  }

  eq(value: string | Decimal): boolean {
    return this._value.eq(Decimal._unwrap(value));
  }

  gt(value: string | Decimal): boolean {
    return this._value.gt(Decimal._unwrap(value));
  }

  gte(value: string | Decimal): boolean {
    return this._value.gte(Decimal._unwrap(value));
  }

  lt(value: string | Decimal): boolean {
    return this._value.lt(Decimal._unwrap(value));
  }

  lte(value: string | Decimal): boolean {
    return this._value.lte(Decimal._unwrap(value));
  }

  isPositive(): boolean {
    return this._value.isPositive();
  }

  isNonZeroPositive(): boolean {
    return this._value.isPositive() && !this._value.isZero();
  }

  isZeroOrPositive(): boolean {
    // -0 will be included
    return this._value.isPositive() || this._value.isZero();
  }

  isNegative(): boolean {
    return this._value.isNegative();
  }

  isNonZeroNegative(): boolean {
    return !this._value.isPositive() && !this._value.isZero();
  }

  isZeroOrNegative(): boolean {
    // 0 will be included
    return this._value.isNegative() || this._value.isZero();
  }

  isZero(): boolean {
    return this._value.isZero();
  }

  /**
   * Used for sorting functions.
   *
   * @param value Value to compare to
   * @returns
   * 1 if the value of this Decimal is greater than `value`,
   * -1 if the value of this Decimal is less than `value`
   * 0 if this Decimal and `value` are the same
   */
  comparedTo(value: string | Decimal): number {
    return this._value.comparedTo(Decimal._unwrap(value));
  }

  /**
   * The number of decimal places, i.e. the number of digits after the decimal
   * point, of the value of this Decimal.
   */
  decimalPlaces(): number {
    return this._value.decimalPlaces();
  }

  toDecimalPlaces(value: number, mode?: RoundingMode): Decimal {
    return new Decimal(
      this._value.toDP(value, mode ?? RoundingMode.ROUND_HALF_UP),
      `${this._value} toDecimalPlaces(${value})`,
    );
  }

  toFixed(decimalPlaces?: number): string {
    return this._value.toFixed(decimalPlaces);
  }

  format({
    fixedPointDigits,
    currencyCode,
    showPositiveSign,
    hideNegativeSign,
    indicateNonZero,
    round,
    smartRound,
    separateThousands,
  }: FormatOptions = {}): { text: string; isSummarised?: boolean } {
    const currencySymbol = currencyCode == "AUD" ? "$" : "";

    // sign is determined above
    let value = this._value.abs();

    // add rounding
    value = round
      ? value.toDecimalPlaces(
          round.decimalPlaces,
          round.mode ?? DecimalCore.rounding,
        )
      : value;

    // add smart rounding
    if (smartRound) {
      let significantDigits = 4;
      let decimalPlaces = 4;

      if (smartRound !== true) {
        significantDigits =
          smartRound.minSignificantDigits ?? significantDigits;
        decimalPlaces = smartRound.maxDecimalPrecision ?? decimalPlaces;
      }

      value = value.gte("1")
        ? value.toDecimalPlaces(decimalPlaces)
        : value.toSignificantDigits(significantDigits);
    }

    let numberValue =
      fixedPointDigits != null
        ? value.toFixed(fixedPointDigits)
        : value.toFixed(); // format without scientific notation

    const showApproximate =
      indicateNonZero && !this.isZero() && Decimal.from(numberValue).isZero();

    const sign = showApproximate
      ? "≈ "
      : showPositiveSign && this.isNonZeroPositive()
        ? "+"
        : !hideNegativeSign && this.isNonZeroNegative()
          ? "-"
          : "";

    // apply separateThousands
    numberValue = separateThousands
      ? formatStringWithCommas(numberValue)
      : numberValue;

    return {
      text: `${sign}${currencySymbol}${numberValue}`,
      isSummarised: showApproximate,
    };
  }

  private static _unwrap(value: string | Decimal): DecimalCore | string {
    return value instanceof Decimal ? value._value : value;
  }
}

export const lesserOf = (a: Decimal, b: Decimal): Decimal => (a.lt(b) ? a : b);
export const greaterOf = (a: Decimal, b: Decimal): Decimal => (a.gt(b) ? a : b);

/* https://mikemcl.github.io/decimal.js/#modes */
export enum RoundingMode {
  ROUND_UP = 0,
  ROUND_DOWN = 1,
  ROUND_CEIL = 2,
  ROUND_FLOOR = 3,
  ROUND_HALF_UP = 4,
  ROUND_HALF_DOWN = 5,
  ROUND_HALF_EVEN = 6,
  ROUND_HALF_CEIL = 7,
  ROUND_HALF_FLOOR = 8,
}

export type FormatOptions = {
  fixedPointDigits?: number;
  currencyCode?: "AUD";
  showPositiveSign?: boolean;
  hideNegativeSign?: boolean;
  indicateNonZero?: boolean;
  separateThousands?: boolean;
  smartRound?:
    | boolean
    | {
        maxDecimalPrecision?: number;
        minSignificantDigits?: number;
      };
  round?: {
    decimalPlaces: number;
    mode?: RoundingMode;
  };
};
