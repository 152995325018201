import { startOfDay, addYears } from "date-fns";
import { padStart } from "lodash";
import { DEFAULT_PARCEL_METHOD } from "../types/helpers/constants";
import { ParcelMethod, ParcelMethodType } from "../types/models/AccountBase";
import { FinancialYear } from "../types/models/FinancialYear";

/**
 * Regex for a financial year string of the format `FY20XX/XX`
 */
export const financialYearRegex = /^FY20[0-9][0-9]\/[0-9][0-9]$/;

/**
 * Converts a financial year string to an array of date objects, the first is the start of the date range, the second is the end of the date range
 *
 * @author Aidan Kinzett
 * @param financialYear String of the format `FY20XX/XX`
 */
export const financialYearToDateRange = (
  financialYear: string,
): [Date, Date] => {
  // check that the financial year is valid
  if (!financialYearRegex.test(financialYear)) {
    throw Error(
      `Invalid financial year ${financialYear}, expecting string of format 'FY20XX/XX'`,
    );
  }

  // remove the FY from the front of the string and split the string into an array of years
  const [startYear, endYear] = financialYear.replace("FY", "").split("/");

  const startDate = new Date(`${startYear}/07/01`); // start of july
  const endDate = new Date(`20${endYear}/07/01`); // start of july the following year, since endDate is exclusive

  return [startDate, endDate];
};

// to check `FY2021/22` is current financial year or not
export const isCurrentFinancialYear = (financialYear: string) => {
  const [startDate, endDate] = financialYearToDateRange(financialYear);
  const today = startOfDay(new Date());

  return today >= startDate && today <= endDate;
};

/**
 * This function returns the financial ending year for a given date
 * @param date The date to return the year the financial year ends on
 * @returns Returns the financial end year that the date lands on
 */
export function returnFinancialEndYear(date: Date): number {
  // If the date is before July 1st, return the current year as the financial year
  if (date.getMonth() < 6) {
    return date.getFullYear();
  }
  // Otherwise add 1 to the financial year, because the financial year ends on the following year
  return date.getFullYear() + 1;
}

/**
 * This function calculates the start date of the financial year for the year that the provided date lands on.
 *
 * @param date The date to retrieve the financial year start date for
 * @returns Returns the financial year start date for a given date
 */
export const returnFinancialYearStartDate = (date: Date) => {
  const financialYearDate = new Date(date.getTime());

  // If the date is before July, set its year to be the year before
  if (financialYearDate.getMonth() < 6) {
    financialYearDate.setFullYear(financialYearDate.getFullYear() - 1);
  }

  // Set these values so the returned date is on the 1st of July, 00:00:00
  financialYearDate.setMonth(6);
  financialYearDate.setDate(1);
  financialYearDate.setHours(0, 0, 0, 0);

  return financialYearDate;
};

export const getFinancialYearForDate = (date: Date) =>
  formatFinancialYear(returnFinancialYearStartDate(date).getFullYear());

export const getLastFinancialYear = () =>
  getFinancialYearForDate(addYears(new Date(), -1));

/**
 * Return the financial year value +- `inc` years from the given year
 *
 * @param financialYear
 * @param inc
 */
export const adjustFinancialYear = ({
  financialYear,
  inc,
}: {
  financialYear: string;
  inc: number;
}) => {
  const [startDate] = financialYearToDateRange(financialYear);
  return getFinancialYearForDate(addYears(startDate, inc));
};

export const getCurrentFinancialYear = () =>
  getFinancialYearForDate(new Date());

export const formatFinancialYear = (financialYear: number) =>
  // e.g. FY2004/05
  `FY${financialYear}/${padStart(
    ((financialYear + 1) % 100).toString(),
    2,
    "0",
  )}`;

/**
 * This function simply finds a parcel method strategy for a given financial year, if none is found it returns FIFO as the default
 *
 * @param date The financial year to find the parcel strategy setting for
 * @param parcelMethods All the parcel strategy settings for all years for the user
 * @param userDefault
 * @returns Returns the found parcel strategy
 */
export const returnParcelMethodForDate = ({
  date = new Date(),
  parcelMethods,
  defaultParcelMethod,
}: {
  date?: Date;
  parcelMethods?: ParcelMethod[];
  defaultParcelMethod?: ParcelMethodType;
} = {}): ParcelMethodType => {
  const yearSettings = parcelMethods?.find(
    (method) => method.endYear === returnFinancialEndYear(date).toString(),
  );

  return yearSettings?.method ?? defaultParcelMethod ?? DEFAULT_PARCEL_METHOD;
};

export const returnParcelMethodForFinancialYear = ({
  financialYear,
  parcelMethods,
  defaultParcelMethod,
}: {
  financialYear: FinancialYear;
  parcelMethods?: ParcelMethod[];
  defaultParcelMethod?: ParcelMethodType;
}): ParcelMethodType => {
  return returnParcelMethodForDate({
    date: financialYear.startDate,
    parcelMethods,
    defaultParcelMethod,
  });
};
