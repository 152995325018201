export enum AddWalletType {
  CSV = "csv",
  Sync = "sync",
  Auth = "auth",
  Empty = "empty",
}

export type AddWalletRequestBody = {
  dataSourceId?: string;
  apiKey?: string;
  secretKey?: string;
  passphrase?: string;
  customName?: string;
  fromDate?: Date;
  type: AddWalletType;
  network?: string;
};

export const CUSTOM_NAME_MAX_LENGTH = 45;
